@tailwind base;
@tailwind components;
@tailwind utilities;

/* @import 'lightgallery.js/dist/css/lightgallery.css'; */
/* @import 'lightgallery.js/dist/css/lightgallery.css'; */

@font-face {
  font-family: 'TTNormsPro';
  src: url('/public/fonts/TTNormsPro-Thin.woff2') format('woff2'),
    url('/public/fonts/TTNormsPro-Thin.woff') format('woff');
  font-style: normal;
  font-display: swap;
  font-weight: 100;
}
@font-face {
  font-family: 'TTNormsPro';
  src: url('/public/fonts/TTNormsPro-Light.woff2') format('woff2'),
    url('/public/fonts/TTNormsPro-Light.woff') format('woff');
  font-style: normal;
  font-display: swap;
  font-weight: 300;
}
@font-face {
  font-family: 'TTNormsPro';
  src: url('/public/fonts/TTNormsPro-Regular.woff2') format('woff2'),
    url('/public/fonts/TTNormsPro-Regular.woff') format('woff');
  font-style: normal;
  font-display: swap;
  font-weight: 400;
}
@font-face {
  font-family: 'TTNormsPro';
  src: url('/public/fonts/TTNormsPro-Medium.woff2') format('woff2'),
    url('/public/fonts/TTNormsPro-Medium.woff') format('woff');
  font-style: normal;
  font-display: swap;
  font-weight: 500;
}
@font-face {
  font-family: 'TTNormsPro';
  src: url('/public/fonts/TTNormsPro-Bold.woff2') format('woff2'),
    url('/public/fonts/TTNormsPro-Bold.woff') format('woff');
  font-style: normal;
  font-display: swap;
  font-weight: 700;
}
@font-face {
  font-family: 'TTNormsPro';
  src: url('/public/fonts/TTNormsPro-ExtraBold.woff2') format('woff2'),
    url('/public/fonts/TTNormsPro-ExtraBold.woff') format('woff');
  font-style: normal;
  font-display: swap;
  font-weight: 800;
}
@font-face {
  font-family: 'TTNormsPro';
  src: url('/public/fonts/TTNormsPro-ExtraBlack.woff2') format('woff2'),
    url('/public/fonts/TTNormsPro-ExtraBlack.woff') format('woff');
  font-style: normal;
  font-display: swap;
  font-weight: 900;
}

@font-face {
  font-family: 'Ethna';
  src: url('/public/fonts/ethna_thin.ttf') format('truetype');
  font-style: normal;
  font-display: swap;
  font-weight: 100;
}

@font-face {
  font-family: 'Ethna';
  src: url('/public/fonts/ethna-thin_Regular.ttf') format('truetype');
  font-style: normal;
  font-display: swap;
  font-weight: 400;
}

@font-face {
  font-family: 'Ethna';
  src: url('/public/fonts/EthnaLightItalic.otf') format('truetype');
  font-style: italic;
  font-display: swap;
  font-weight: 300;
}

@font-face {
  font-family: 'Ethna';
  src: url('/public/fonts/EthnaBoldItalic.otf') format('truetype');
  font-style: italic;
  font-display: swap;
  font-weight: 700;
}

@layer components {
  .container_padding {
    @apply px-6 md:px-14;
  }
}
html {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  background-color: #fff;
}

* {
  box-sizing: border-box;
  font-family: 'TTNormsPro', sans-serif !important;
}
h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
}

a {
  text-decoration: none;
}

ul {
  margin-block-start: 0;
  margin-block-end: 0;
  margin-inline-start: 0;
  margin-inline-end: 0;
  padding-inline-start: 0;
  list-style-type: none;
}

/* width */
::-webkit-scrollbar {
  width: 3px;
  height: 3px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.cursor {
  cursor: pointer;
}

button {
  cursor: pointer;
}

input {
  outline: none !important;
  box-shadow: none !important;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type='number'] {
  appearance: textfield;
}
input[type='date'] {
  cursor: text !important;
}
textarea {
  resize: none !important;
}
button {
  outline: none !important;
  box-shadow: none !important;
  cursor: pointer;
}

input[type='date']:valid:active,
input[type='date']:valid:focus,
input[type='date']:valid:focus-visible,
input[type='date']:valid:focus-within,
input[type='date']:valid:target,
input[type='date']:valid:active {
  color: #0a0d2c !important;
}

input[type='date']:valid {
  color: #767993;
}
input[type='date']:invalid {
  color: #0a0d2c !important;
}

#toast-container > div {
  opacity: 1;
  border-radius: 8px;
}

/* online_film_slider */
.online_film_slider .swiper-button-prev,
.swiper-button-next {
  color: #202021 !important;
  z-index: 3;
}
.online_film_slider .swiper-button-next:after,
.swiper-rtl .swiper-button-prev:after {
  font-size: 27px !important;
  z-index: 3;
}
.online_film_slider .swiper-button-prev:after,
.swiper-rtl .swiper-button-next:after {
  font-size: 27px !important;
}

.online_film_slider .swiper-button-next {
  right: 210px;
  z-index: 3;
}

@media (max-width: 1280px) and (min-width: 996px) {
  .online_film_slider .swiper-button-next {
    right: 270px !important;
  }
}
@media (max-width: 996px) and (min-width: 768px) {
  .online_film_slider .swiper-button-next {
    right: 240px !important;
  }
}
@media (max-width: 768px) and (min-width: 320px) {
  .online_film_slider .swiper-button-next {
    right: 15px !important;
  }
}
.swiper-wrapper {
  align-items: center !important;
}
/* online_film_slider */

/* uzbek-cuisine swiper */
.uzbek-cuisine .swiper-wrapper .swiper-slide-active,
.swiper-slide-activeeee {
  width: 272px !important;
  height: 453px !important;
}
.uzbek-cuisine .swiper-wrapper .swiper-slide-active img {
  width: 100% !important;
  height: 100% !important;
}
.uzbek-cuisine .swiper-wrapper {
  display: flex;
  align-items: center;
  width: max-content;
}
.uzb_c .swiper-paginationn .swiper-pagination-bullet-active {
  background: #ffff !important;
  opacity: 1 !important;
}
.uzb_c .swiper-paginationn .swiper-pagination-bullet {
  background: #ffff !important;
  opacity: 0.15;
}
/* uzbek-cuisine swiper */
.slick-slider {
  width: calc(100% + 32px);
  margin-left: -18px;
}
/* .slick-slide {
	width: 313px !important;
} */
.slick-slide > div {
  margin: 0 8px;
}
.slider_visible .slick-list {
  margin: 0 8px;
  overflow: visible !important;
  position: relative;
}

.slick_container_white,
.slick_container_white2,
.slick_container_dark,
.slick_container_dark2,
.slick_container_grey {
  position: relative;
}
.slick_container_white::after,
.slick_container_white2::after {
  background: white;
}
.slick_container_dark::after,
.slick_container_dark2::after {
  background: #202021;
}
.slick_container_grey::after {
  background: #ececec;
}
.slick_container_white::after,
.slick_container_dark::after,
.slick_container_grey::after {
  content: '';
  width: 200%;
  left: -200%;
  height: calc(100% + 40px);
  top: -10px;
  transform: translate(8px, 0);
  z-index: 22;
  display: block;
  position: absolute;
}

.slick_container_white2::after,
.slick_container_dark2::after {
  content: '';
  width: 200%;
  left: -200%;
  height: calc(100% + 40px);
  top: -10px;
  transform: translate(305px, 0);
  z-index: 9;
  display: block;
  position: absolute;
}
.slick-arrow {
  display: none !important;
}

/* gallery slider */
.fancybox__container {
  z-index: 99999 !important;
}
/* gallery slider */

/* Circles */
.circle:nth-child(1),
.circle:nth-child(2),
.circle:nth-child(3) {
  width: 4px;
  height: 4px;
  margin-right: 2.5px;
  border-radius: 50%;
  animation: jump 0.8s ease-in infinite;

  background-color: #fff;
}

.circle:nth-child(2) {
  animation-delay: 0.15s;
}

.circle:nth-child(3) {
  animation-delay: 0.25s;
}

/*CustomCalendar Style*/
.custom_calendar .swiper-wrapper .swiper-slide-active .month {
  display: block !important;
}

/* Animation */
@keyframes jump {
  0% {
    transform: translateY(0px);
  }

  50% {
    transform: translateY(3px);
  }

  100% {
    transform: translateY(0px);
  }
}

@keyframes spin-slow {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.animate-spin-slow {
  animation: spin-slow 45s linear infinite;
}

@keyframes move-horizontal {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(-100%);
  }
}

.animate-move-horizontal {
  animation: move-horizontal 10s linear infinite;
}
/* loader */

.toggle-switch {
  display: flex;
  align-items: center;
  gap: 8px;
}

.switch {
  width: 50px;
  height: 25px;
  border-radius: 50px;
  background-color: #ccc;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 2px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.switch.on {
  background-color: #0f355b;
  justify-content: flex-end;
}

.knob {
  width: 20px;
  height: 20px;
  background-color: white;
  border-radius: 50%;
  transition: transform 0.3s ease;
}
.ql-editor {
  min-height: 150px !important;
}
